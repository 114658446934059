<template>
  <a-modal
    :visible="pricevisible"
    :centered="true"
    :width="640"
    title="机翻收费提示"
    :maskClosable="false"
    @cancel="cancel"
  >
    <div class="price-content">
      <div class="price-box" v-if="detail.mustToBilling">
        <div class="price-box-left">
          <img src="./images/price.png" />
        </div>
        <div class="price-box-right">
          <div class="price-box-item">
            <label>支付账户：</label>
            <div>{{ detail.accountName }}</div>
          </div>
          <div class="price-box-item">
            <label>机翻引擎：</label>
            <div>{{ detail.twinslatorName }}</div>
          </div>
          <div class="price-box-item">
            <label>单价：</label>
            <div>
              {{ detail.etokenPrice * 1000 }}译力/千字
              <span class="font-size-12 color-999" v-if="detail.etokenPrice">
                （约合{{ (detail.etokenPrice * 10).toFixed(2) }}元/千字）
              </span>
            </div>
          </div>
          <div class="price-box-item">
            <label>翻译总字数：</label>
            <div>{{ detail.wordCount }}</div>
          </div>
          <div class="price-box-item">
            <label>扣除字数包：</label>
            <div>
              <span class="payPrice">{{ detail.payFlowCount || '-' }}</span>
              <span class="rate">
                实际抵扣字数：
                <span class="black">{{ detail.flowWordCount || '-' }}</span>
                （抵扣比例 1：{{ detail.flowRatio }}）
                <a-tooltip
                  :getPopupContainer="
                    (triggerNode) => {
                      return triggerNode.parentNode || document.body
                    }
                  "
                >
                  <template #title>
                    温馨提示：字数包规格字数为根据谷歌引擎单价字数计算，不同引擎按照相应的比例抵扣。
                    <a class="color-00C6F3 cursor-pointer" @click="toRecharge">
                      详情参见字数包计费说明。
                    </a>
                  </template>
                  <QuestionCircleOutlined />
                </a-tooltip>
              </span>
            </div>
          </div>
          <div class="price-box-item">
            <label>扣除译力：</label>
            <div>
              <span class="payPrice">{{ detail.payEtoken || '-' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        翻译{{detail.wordCount}}字数，需支付<span style="color:#00B4FA">0</span>译力,是否确认支付?
      </div>
      <div class="margin-t-20">
        <a-checkbox v-model:checked="checked">
          <span style="color:#333">
            下次使用孪生译员时，若译力足够
            <span style="color:#00BEFA">自动扣费</span>
            ，不再提醒
          </span>
        </a-checkbox>
      </div>
    </div>

    <template #footer>
      <div class="price-footer">
        <div class="left" v-if="detail.mustToBilling">
          <img src="./images/yili.png" />
          <span>当前译力余额：{{ detail.remainingEtoken }}</span>
          <img src="./images/zishubao.png" />
          <span>当前字数包余额：{{ detail.remainingFlowCount }}</span>
        </div>
        <div v-else>

        </div>
        <div class="right" v-if="detail.paySource == 'enterprise'">
          <a-button key="back" size="default" @click="cancel">
            取消
          </a-button>
          <a-button
            key="submit"
            type="primary"
            size="default"
            class="margin-l-10"
            @click="confirm"
          >
            确定
          </a-button>
        </div>
        <div class="right" v-else>
          <a-button key="back" size="default" @click="cancel">
            取消
          </a-button>
          <a-button
            key="submit"
            type="primary"
            size="default"
            class="margin-l-10"
            @click="confirm"
          >
            {{ detail.isEnough || !detail.mustToBilling ? '确定' : '前往充值' }}
          </a-button>
        </div>
      </div>
    </template>
    <!--    </div>-->
  </a-modal>
</template>

<script>
  import {
    QuestionCircleFilled,
    QuestionCircleOutlined,
  } from '@ant-design/icons-vue'
  import { computed, ref } from 'vue'
  import { $storageUser } from '@/plugins/proxyStorage'
  import { useStore } from 'vuex'
  import { getTwinsSetStatus, postTwinsSetStatus } from '@/http/api'

  export default {
    components: {
      QuestionCircleOutlined,
      QuestionCircleFilled,
    },
    props: ['pricevisible', 'detail'],
    setup(props, ctx) {
      const loading = ref(false)
      const store = useStore()

      const checked = computed({
        set(val) {
          store.commit('modal/changeKey', {
            autoPayChecked: val,
          })
        },
        get() {
          return store.state.modal.autoPayChecked
        },
      })

      const autoRecommendChecked = computed({
        set(val) {
          store.commit('modal/changeKey', {
            autoRecommendChecked: val,
          })
        },
        get() {
          return store.state.modal.autoRecommendChecked
        },
      })

      const cancel = () => {
        ctx.emit('update-pricevisible', false)
        loading.value = false
        getTwinsSetStatus().then((res) => {
          let result = res.data.data
          // 自动支付
          checked.value = result.autoPay
        })
      }
      const confirm = () => {
        // if (props.detail.isEnough && checked.value) {
        //   // $storageUser.getMtTip = true;
        //   // postTwinsSetStatus({
        //   //   autoPay: checked.value,
        //   //   autoRecommend: autoRecommendChecked.value
        //   // });
        // }

        // if (props.detail.isEnough && (typeof props.detail.remainingEtoken != 'object')) {
        //   //确定
        //   loading.value = true;
        //   ctx.emit("confirm-order", true);
        //   cancel();
        // } else {
        //   //前往充值
        //   loading.value = true;
        //   window.open(window.env.recharge, "_blank");
        //   canc
        //   el();
        // }

        if (props.detail.paySource == 'enterprise') {
          //如果是企业需要判断哪种不够
          if (!props.detail.pmEnough) {
            // PM额度设置不够
            cancel()
            store.commit('modal/changeKey', {
              PMLimitVisible: true,
            })
          } else if (!props.detail.enterpriseEnough) {
            // 企业余额不够
            cancel()
            store.commit('modal/changeKey', {
              balanceNoEnoughVisible: true,
            })
          } else {
            postTwinsSetStatus({
              autoPay: checked.value,
              autoRecommend: autoRecommendChecked.value,
            })

            ctx.emit('confirm-order', true)
          }
        }

        // 支付方为个人 直接打开弹窗即可
        else if (props.detail.paySource == 'personal') {
          // detail.value = { ...res.data.data };
          // callback.value = event.ok;
          postTwinsSetStatus({
            autoPay: checked.value,
            autoRecommend: autoRecommendChecked.value,
          })

          ctx.emit('confirm-order', true)
        }
      }

      // 去往充值页面
      const toRecharge = () => {
        window.open(`/go/wealth_pc_login/recharge?tabs=2&openModal=1`, '_blank')
      }

      return {
        loading,
        cancel,
        confirm,
        toRecharge,
        checked,
      }
    },
  }
</script>
<style lang="scss" scoped>
  .price-box {
    display: flex;
    .price-box-left {
      flex-shrink: 0;
      margin-right: 36px;
      img {
        width: 48px;
      }
    }
    .price-box-right {
      flex-grow: 1;
      padding: 10px 20px;
      background: #f6f6f6;
      border-radius: 8px;
    }
    .price-box-item {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 8px 0;
      label {
        color: #666666;
        flex-basis: 90px;
        margin-right: 30px;
        flex-shrink: 0;
      }
      &:not(:last-child) {
        border-bottom: 1px dashed #dddddd;
      }
      & > div {
        color: #333333;
      }
      .payPrice {
        color: #00befa;
        margin-right: 20px;
      }
      .rate {
        font-size: 12px;
        color: #999999;
      }
      .black {
        color: #333;
      }
    }
  }
  .price-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }
    span {
      color: #666;
      margin-right: 20px;
    }
  }
</style>
